// Here you can add other styles
.locked-account {
  background-color: var(--color);
  color: #fff;
  width: 14px;
  border-radius: 50%;
  padding: 2px;
  margin-left: 5px;
}

.tab-content .table th {
  border-top: 0 none;
}

.btn {
  white-space: nowrap;
}

.image-picker {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 10px 0;
  padding: 0;
  max-height: 230px;
  overflow: auto;
  width: calc(100% + 30px);
  margin-left: -15px;
  background-color: #f6f6f6;
  padding: 10px 10px 0 10px;
  box-shadow: inset 0 0 1em rgba(0, 0, 0, 0.1);

  li {
    width: 85px;
    height: 85px;
    display: flex;
    position: relative;
    margin: 0 10px 10px 0;
    border: 1px solid #ccc;
    border-radius: 3px;
    overflow: hidden;
  }

  label {
    margin: 0;
    display: block;
    cursor: pointer;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  input {
    position: absolute;
    left: 5px;
    top: 5px;
  }

  &.banners li {
    width: 228px;
  }
}
